import { Component } from "react";
import styled, { css } from "styled-components";
import Gutter from "../Gutter";
import Row from "../Row";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Font, media, rem, Color } from "../../utils/style";
import { graphql } from "gatsby";
import RitualButton from "../global/RitualButton";

const Annotation = styled.div`
  position: absolute;
  width: 100%;
  top: ${(p) => {
    return p.top ? `${p.top}px` : "15px";
  }};

  ${media.tablet`
        position: static;
     `};
`;
const AnnotationLineWrapper = styled.div`
  border-top: 3px solid ${Color.ritualBlue};
  width: 100%;

  &:after {
    content: "";
    border: 3px solid ${Color.ritualBlue};
    border-radius: 10px;
    padding: 4px;
    right: -12px;
    top: -6px;
    position: absolute;
  }

  ${media.tablet`
    border-top: 0;
    margin: 30px 0;

    &:after {
      content: none;
    }
  `};
`;

const Title = styled.h3`
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  ${Font.circular} font-weight: 400;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(30)};
  margin: ${rem(16)} 0 0;

  ${media.tablet`
        margin-top: 22px;
        margin-bottom: 16px;
        border-top: 2px solid ${Color.ritualBlue};
        padding-top: 22px;
     `};
`;

const Description = styled.div`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  ${Font.dutch} font-style: italic;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(26)};
  margin-bottom: ${rem(16)};
`;

const ContainedImage = styled.div`
  width: 280px;
  height: 180px;
  position: absolute;
  top: -180px;

  ${media.tablet`
      top: 0;
      position: relative;
      margin-bottom: 1rem;
    `};
`;

const Content = styled.div.attrs({
  className: "col-12 col-md-9",
})`
  position: relative;
  z-index: 10;

  ${(p) => {
    return p.hasImage
      ? css`
          padding-right: 10px;
          background-color: white;
          margin-top: -40px;
        `
      : "";
  }};

  ${media.tablet`
    margin-top: 0;
  `};
`;

const CtaLink = styled(RitualButton)`
  width: auto;
  padding: 12px 32px;
  max-width: 280px;
`;

export default class GutterAnnotation extends Component {
  renderImage() {
    let { image, title } = this.props;
    return (
      <ContainedImage>
        <GatsbyImage
          className="callout-image"
          image={getImage(image)}
          loading="eager"
          alt={title}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
      </ContainedImage>
    );
  }

  render() {
    let {
      title,
      detailCopy,
      top,
      image,
      ctaText,
      ctaUrl,
      className,
      hideGutter,
    } = this.props;
    let hasCta = ctaText && ctaUrl,
      hasImage = image != null;
    return (
      <Gutter hideGutter={hideGutter} className={className}>
        <Annotation top={top}>
          <AnnotationLineWrapper>
            <Row>
              <Content hasImage={hasImage} hasCta={hasCta}>
                {hasImage && this.renderImage()}
                <Title>{title}</Title>
                <p>
                  <Description>{detailCopy}</Description>
                </p>
                {hasCta && (
                  <CtaLink to={ctaUrl}>
                    <span>{ctaText}</span>
                  </CtaLink>
                )}
              </Content>
            </Row>
          </AnnotationLineWrapper>
        </Annotation>
      </Gutter>
    );
  }
}

export const myQuery = graphql`
  fragment gutterSummary on ContentfulGutterAnnotation {
    __typename
    title
    detailCopy
    ctaText
    ctaUrl
    image {
      file {
        url
      }
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 280
        height: 180
        quality: 100
      )
    }
  }
`;
