import { Component } from "react";
import styled from "styled-components";
import Gutter from "../Gutter";
import { Font, media, rem, Color, maxLines } from "../../utils/style";
import RitualButton from "../global/RitualButton";
import { graphql } from "gatsby";

const Annotation = styled.div`
  position: relative;
  top: ${(p) => {
      return p.top ? `${p.top}px` : "45px";
    }}
    ${media.tablet`
        position: static;
     `};
`;

const CtaContent = styled.div`
  border: 2px solid ${Color.ritualBlue};
  padding: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  transition: all 0.3s;

  * {
    transition: all 0.3s;
  }

  &:hover {
    background: ${Color.ritualBlue};
    color: white;

    * {
      color: white;
    }

    .fact-link {
      background: white !important;

      span {
        color: ${Color.ritualBlue} !important;
      }
    }
  }

  ${media.notDesktop`
      padding: 40px 24px;
  `} ${media.tablet`
       margin: 30px 0;
   `};
`;

const Box = styled.div`
  padding: 0;
  width: 100%;
`;

const CtaTitle = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: ${rem(26)};
`;

const CtaCopy = styled.p`
  ${Font.dutch} margin: 64px 0 32px;
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-weight: normal;
  text-align: left;

  ${maxLines(26, 3)} ${media.notDesktop`
    font-size: ${rem(18)};
    line-height: ${rem(24)}
    ${maxLines(24, 3)}
  `};
`;

const CtaLink = styled(RitualButton)`
  width: 100%;
`;

export default class GutterCta extends Component {
  render() {
    let {
      title,
      shortCopy,
      ctaText,
      ctaUrl,
      top,
      overrideContent,
      hideGutter,
      className,
    } = this.props;
    if (overrideContent || !title) {
      return <Gutter>{overrideContent}</Gutter>;
    }
    return (
      <Gutter hideGutter={hideGutter} className={className}>
        <Annotation top={top}>
          <CtaContent>
            <Box>
              <CtaTitle>{title}</CtaTitle>
              <CtaCopy>{shortCopy}</CtaCopy>
              <CtaLink to={ctaUrl}>
                <span>{ctaText}</span>
              </CtaLink>
            </Box>
          </CtaContent>
        </Annotation>
      </Gutter>
    );
  }
}

export const myQuery = graphql`
  fragment gutterCtaSummary on ContentfulGutterCta {
    __typename
    title
    shortCopy
    ctaUrl
    ctaText
  }
`;
