import { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import $ from "jquery";
import styled from "styled-components";
import GutterElement from "./GutterElement";
import { rem, atMost, media, Font, Color } from "../../utils/style";

const Content = styled.div`
  * {
    ${Font.dutch}
  }

  p {
    ${Font.dutch}
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    margin: 0 0 ${rem(26)};

    &:last-of-type {
      margin: 0;
    }

    ${media.mobile`
      font-size: ${rem(17)};
      line-height: ${rem(28)};
    `}
  }

  h1,
  h2,
  h3 {
    ${Font.circular}
    font-weight: 400;
    margin-bottom: ${rem(16)};

    em {
      ${Font.dutch}
    }
  }

  a {
    ${Font.dutch}
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${Color.ritualBlue};

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${Color.ritualYellow};
    }
  }

  li {
    font-size: ${rem(20)};

    &::marker {
      ${Font.circular}
    }
  }

  strong,
  em {
    ${Font.circular}
  }

  &.answer {
    position: relative;
    padding-left: 70px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: ${Color.ritualBlue};
    }

    ${media.mobile`
      padding-left: 45px;
    `}
  }
`;

export default class ContentWithCallout extends Component {
  constructor(props) {
    super(props);
    this.answerRef = undefined;
    this.shouldInterpolate = false;
    this.state = { isMobile: false };
  }

  componentDidMount() {
    let { hideGutter, content, gutterItem } = this.props;
    this.updateGutterItemOnResize();
    this.onResize();
    this.shouldInterpolate =
      gutterItem && this.shouldInterpolateAnnotation(content);
    if (hideGutter) {
      hideGutter(this.shouldInterpolate);
    }
  }

  componentWillUnmount() {
    this.unbindResize();
  }

  unbindResize() {
    $(window).off("resize.contentCallout");
  }

  updateGutterItemOnResize() {
    $(window).on("resize.contentCallout", this.onResize.bind(this));
  }

  onResize() {
    this.updateIsMobile();
    this.updateCodePosition();
  }

  updateIsMobile() {
    const shouldBeMobile = atMost.mobile();
    if (this.state.isMobile !== shouldBeMobile) {
      this.setState({ isMobile: shouldBeMobile });
    }
  }

  updateCodePosition() {
    if (this.answerRef) {
      let firstCode = $(this.answerRef).find("code");
      let codeTop = firstCode.length
        ? firstCode.position().top + firstCode.outerHeight() / 2 + 20
        : 15;
      this.props.onCalloutMoved(codeTop);
    }
  }

  regexPeriodAndSpaceAfterCallout() {
    return new RegExp(/<\/code>((.|[\r\n])*?)\.\s/);
  }

  shouldInterpolateAnnotation(content) {
    return this.regexPeriodAndSpaceAfterCallout().test(content);
  }

  insertAnnotationAfterEndOfSentence(content, gutterItem) {
    let gutterItemHtml = renderToStaticMarkup(
      <GutterElement {...gutterItem} />,
    );
    let nestableContent = content;
    let regexResult = "$&"; // Regex syntax for any matches found
    return nestableContent.replace(
      this.regexPeriodAndSpaceAfterCallout(),
      regexResult + "</p>" + gutterItemHtml + "<p>",
    );
  }

  renderContent(content, type) {
    return (
      <Content
        className={type ? type : ""}
        ref={(r) => {
          this.answerRef = r;
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  renderContentWithAnnotation(content, type) {
    return (
      <div>
        <Content
          className={type ? type : ""}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    );
  }

  render() {
    let { content, gutterItem, type } = this.props;
    let { isMobile } = this.state;
    let contentWithAnnotation = this.shouldInterpolate
      ? this.insertAnnotationAfterEndOfSentence(content, gutterItem)
      : content;
    return (
      <div>
        {isMobile
          ? this.renderContentWithAnnotation(contentWithAnnotation, type)
          : this.renderContent(content, type)}
      </div>
    );
  }
}
