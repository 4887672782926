import styled from "styled-components";
import { Font, responsive } from "../../utils/style";
import { Color } from "../../utils/styleDesignSystem";

const MarketingConsentContainer = styled.form`
  display: flex;
  padding: 0;
  align-items: flex-start;
  column-gap: var(--spacing-0_5, 8px);
  margin-top: var(--spacing-1, 16px);

  ${responsive.sm`
  column-gap: var(--spacing-1, 16px);
  align-items: center;
  `};
`;

const MarketingConsentText = styled.label`
  ${Font.circular};
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 0;
  cursor: pointer;
`;

const MarketingOptInCheckbox = styled.input`
  accent-color: ${Color.indigoBlue};
  margin-top: 4px;
  cursor: pointer;

  ${responsive.sm`
    margin-top: 0;
  `};
`;

const UnsubscribeText = styled.span`
  font-style: italic;
`;

const MarketingConsent = ({
  marketingPreference,
  onMarketingPreferenceChange,
}: {
  marketingPreference: boolean | null;
  onMarketingPreferenceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <MarketingConsentContainer>
      <MarketingOptInCheckbox
        type="checkbox"
        checked={Boolean(marketingPreference)}
        onChange={onMarketingPreferenceChange}
        aria-labelledby="marketing-consent-text"
        name="marketing-consent"
        id="marketing-consent"
      />
      <MarketingConsentText
        className="typography-label3 font-circular"
        id="marketing-consent-text"
        htmlFor="marketing-consent"
      >
        I want to receive updates, tips, and other emails from Ritual{" "}
        <UnsubscribeText className="typography-label3 font-dutch">
          (you can unsubscribe any time)
        </UnsubscribeText>
      </MarketingConsentText>
    </MarketingConsentContainer>
  );
};

export default MarketingConsent;
