import {
  StripeElements,
  StripeError,
  Stripe,
  StripeElementsUpdateOptions,
  CustomPaymentMethod,
  ConfirmationToken,
  Appearance,
} from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";
import { ApplePayOption } from "@stripe/stripe-js/dist/stripe-js/elements/apple-pay";

import Cart, { RecurringBillingDetails } from "../../../store/cart/model";
import fetchInternal from "../../../utils/fetch";
import { StripeFormattedAddress } from "../../../utils/types/validateAddressTypes";
import { Color } from "../../../utils/styleDesignSystem";
import { capitalize } from "../../../utils/style";

type BaseAPIPurchase = {
  order_number: string;
  first_name?: string;
  last_name?: string;
  user_id?: string;
  reset_password_token?: string;
  client_secret?: string;
  requires_action?: boolean;
};

type BaseAPIError = {
  error?: any;
  errors?: any;
};

// TODO: when customPaymentMethods are added to the StripeElementsUpdateOptions type on a future bump to stripe.js, remove this
interface CompleteStripeElementsUpdateOptions
  extends StripeElementsUpdateOptions {
  customPaymentMethods?: CustomPaymentMethod[];
}

interface CartAddressPayload {
  shipping_address: StripeFormattedAddress;
  discount_code?: string;
}

export const tryElementsSubmit = async (
  elements: StripeElements,
): Promise<{
  success: boolean;
  error: StripeError | undefined;
  selectedPaymentMethod: string | undefined;
}> => {
  const { error, selectedPaymentMethod } = await elements.submit();

  const success = !error;

  return { success, error, selectedPaymentMethod };
};

export const tryCreateConfirmationToken = async (
  stripe: Stripe,
  elements: StripeElements,
) => {
  const { confirmationToken, error } = await stripe.createConfirmationToken({
    elements,
  });

  return { confirmationToken, error };
};
export const tryApiPurchase = async ({
  confirmationToken,
  cartId,
}: {
  confirmationToken: ConfirmationToken;
  cartId: string;
}): Promise<{
  response?: BaseAPIPurchase;
  error?: BaseAPIError;
}> => {
  try {
    const response = await fetchInternal("purchase", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      fetchOptions: {
        addCastleRequestToken: true,
      },
      body: JSON.stringify({
        confirmation_token: confirmationToken.id,
        cart_id: cartId,
      }),
    });

    return { response };
  } catch (error) {
    return { error: error as BaseAPIError };
  }
};

export const tryHandleNextAction = async (
  clientSecret: string,
  stripe: Stripe,
) => {
  const { error, paymentIntent, setupIntent } = await stripe.handleNextAction({
    clientSecret,
  });

  return { paymentIntent, setupIntent, error };
};

export const finalizePurchase = async (cartId: string) => {
  await fetchInternal(`purchase/finalize`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    fetchOptions: {
      addCastleRequestToken: true,
    },
    body: JSON.stringify({ cart_id: cartId }),
  });
};

export const stripeAppearanceConfig: Appearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    borderRadius: "4px",
    colorDanger: `${Color.errorRed}`,
    colorPrimary: `${Color.indigoBlue}`,
    colorSuccess: `${Color.successGreen}`,
    colorText: `${Color.indigoBlue}`,
    colorWarning: `${Color.warningOrange}`,
    fontFamily: "CircularXX",
    fontSizeBase: "16px",
    fontVariantLigatures: "normal",
    fontWeightBold: "500",
    fontWeightLight: "450",
    fontWeightMedium: "500",
    fontWeightNormal: "450",
    iconHoverColor: `${Color.indigoBlue40}`,
    iconChevronDownColor: `${Color.indigoBlue}`,
    iconChevronDownHoverColor: `${Color.indigoBlue40}`,
    iconCloseColor: `${Color.indigoBlue}`,
    iconCloseHoverColor: `${Color.indigoBlue40}`,
    iconColor: `${Color.indigoBlue}`,
  },
  rules: {
    ".Input": {
      boxShadow: "none",
    },
    ".AccordionItem": {
      boxShadow: "none",
    },
  },
};

export const stripeElementsConfig = (
  cart: Cart,
  recurringItems: boolean,
  localeId?: string,
  truemedEnabled = false,
): CompleteStripeElementsUpdateOptions => {
  const truemedPaymentMethodId = process.env.GATSBY_TRUEMED_PAYMENT_METHOD_ID;

  const cartTotal = cart?.total || 0;

  const showTruemed =
    truemedEnabled &&
    localeId === "en-US" &&
    truemedPaymentMethodId &&
    cartTotal > 0;

  const customPaymentMethods: CustomPaymentMethod[] = showTruemed
    ? [
        {
          id: truemedPaymentMethodId,
          options: { type: "static", subtitle: "Pay with HSA/FSA" },
        },
      ]
    : [];

  return {
    mode: cart.requiresPaymentIntent ? "payment" : "setup",
    amount: cart.requiresPaymentIntent ? cart.total : undefined,
    currency: cart.currency,
    ...(recurringItems && { setupFutureUsage: "off_session" }),
    appearance: stripeAppearanceConfig,
    customPaymentMethods,
  };
};

export const patchCart = async (payload: CartAddressPayload, cart: Cart) => {
  const response = await fetchInternal(`carts/${cart.id}`, {
    method: "PATCH",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    fetchOptions: {
      addCastleRequestToken: true,
    },
    body: JSON.stringify(payload),
  });

  return response;
};

export const setLocalStorage = (purchase: {
  first_name?: string;
  last_name?: string;
  order_number?: string;
  reset_password_token?: string;
  payment_methods?: string[];
}) => {
  const keys = [
    "first_name",
    "last_name",
    "order_number",
    "reset_password_token",
  ] as const;
  keys.forEach((key) => {
    if (purchase[key]) {
      sessionStorage.setItem(`rit-${key}`, purchase[key] as string);
    }
  });
};

export const getApplePayOption = (
  recurringBillingDetails: RecurringBillingDetails,
  activeCart: Cart,
): ApplePayOption | undefined => {
  const cadences = Object.keys(recurringBillingDetails);

  if (cadences.length !== 1) {
    return;
  }

  const cadence = cadences[0]!;
  const details = recurringBillingDetails[cadence]!;

  return {
    recurringPaymentRequest: {
      paymentDescription: "My Ritual Subscription",
      managementURL: "https://account.ritual.com/",
      regularBilling: {
        amount: details.amount,
        label: `Recurring subscription (${
          activeCart.taxInclusive ? "VAT Included" : "plus tax"
        })`,
        recurringPaymentIntervalUnit: "day",
        recurringPaymentIntervalCount: Number(cadence),
        recurringPaymentStartDate: new Date(
          new Date().setDate(Number(new Date().getDate() + cadence)),
        ),
      },
      billingAgreement: `Some of the items you are purchasing will automatically renew as a subscription and will be billed every ${cadence} days until you cancel. You can cancel anytime by visiting your account page on our website. By clicking Pay, you confirm that you have read, understand, and agree to the Terms of Service and Privacy Policy.`,
    },
  };
};

export type Result<T, E = string> =
  | { success: true; data: T }
  | { success: false; error: E };

type APIError = {
  status?: number;
  [key: string]: unknown;
};

export type TruemedPaymentSession = {
  first_name: string;
  last_name: string;
  user_id: string;
  order_number: string;
  reset_password_token: string;
  redirect_url: string;
};

export const createTruemedPaymentSession = async (
  cartId: string,
  name: string,
  email: string,
): Promise<Result<TruemedPaymentSession, APIError>> => {
  try {
    const response = await fetchInternal("truemed/purchase", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      fetchOptions: {
        addCastleRequestToken: true,
      },
      body: JSON.stringify({ cart_id: cartId, name, email }),
    });

    return { success: true, data: response as TruemedPaymentSession };
  } catch (error) {
    return { success: false, error: error as APIError };
  }
};

export const finalizeTruemedPayment = async (
  cartId: string,
): Promise<Result<{}, APIError>> => {
  try {
    await fetchInternal(
      "truemed/purchase/finalize",
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        fetchOptions: {
          addCastleRequestToken: true,
        },
        body: JSON.stringify({ cart_id: cartId }),
      },
      22,
      500,
    );
    return { success: true, data: {} };
  } catch (error) {
    return { success: false, error: error as APIError };
  }
};

export interface PaymentMethod extends ConfirmationToken.PaymentMethodPreview {
  truemed?: boolean;
}

export const parsePaymentMethod = (
  paymentMethod?: PaymentMethod,
): string | undefined => {
  if (!paymentMethod) return;
  const { truemed, card, type } = paymentMethod;
  if (truemed) {
    return "Truemed";
  }

  if (card) {
    const { brand, last4 } = card;
    return `${capitalize(brand)} ${last4}`;
  }

  if (!type) return;

  return capitalize(type.replace("_", " "));
};

export const getPurchasedCart = () => {
  const cart = sessionStorage.getItem("rit-purchased_cart");
  const cartId = sessionStorage.getItem("rit-cart_id");
  if (!cart) {
    Sentry.captureException({ error: "rit-purchased_cart not found" });
    return {};
  }
  try {
    const parsedCart = JSON.parse(cart);
    if (!parsedCart.id) parsedCart.id = cartId;
    return parsedCart;
  } catch (error) {
    Sentry.captureException(new Error(`Failed to parse cart JSON, ${error}`));
    return {};
  }
};
