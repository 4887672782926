import { Component } from "react";
import styled from "styled-components";
import { media } from "../utils/style";

const GutterContainer = styled.section.attrs({
  className: "col-12 col-md-4",
})`
  position: relative;

  ${media.tablet`
      ${(p) => {
        return p.hideGutter ? "display: none !important;" : "";
      }}
    `};
`;

const GutterSeperator = styled.hr`
  margin: 0;
  width: 0;
  height: 0;
`;

export default class Gutter extends Component {
  render() {
    if (!this.props.children) {
      return <GutterContainer hideGutter={this.props.hideGutter} />;
    }

    return (
      <GutterContainer hideGutter={this.props.hideGutter}>
        <GutterSeperator />
        {this.props.children}
        <GutterSeperator />
      </GutterContainer>
    );
  }
}
