import { useRef } from "react";
import styled from "styled-components";
import metrics from "../../utils/metrics";
import { Font, rem, media } from "../../utils/style";
import MagicVideo from "../MagicVideo";

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(p) => p.zindex};
`;

const VideoElement = styled(MagicVideo)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:focus {
    outline: none;
  }

  video {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-height: 415px) and (orientation: landscape) {
    max-height: calc(100vh - 99px);
  }
`;

const VideoAbout = styled.div`
  position: absolute;
  left: 0;
  opacity: 0;
  bottom: -42px;
  font-size: ${rem(18)};

  ${media.mobile`
    display: flex;
    flex-direction: column;
    bottom: -82px;
    width: 100%;
    text-align: center;
  `};

  @media (max-height: 415px) and (orientation: landscape) {
    max-height: calc(100vh - 99px);
  }
`;

const Name = styled.span`
  margin-right: 8px;
  font-weight: 600;

  ${media.mobile`
    margin-bottom: 4px;
  `};
`;

const Title = styled.span`
  ${Font.dutch};
  font-size: ${rem(16)};
  font-style: italic;
`;

const CarouselVideo = ({ activeSlide, styles }) => {
  const videoRef = useRef(null);
  const { image, name, title, videoContent } = activeSlide;

  const logVideoEvent = (message, event) => {
    metrics.track("Video Testimonials Engagement", {
      action: message,
      position: 1,
      subject: name,
      playback_time: event.target.currentTime,
      video_duration: event.target.duration,
    });
  };

  const onPlay = (event) => {
    logVideoEvent("Video Playback Started", event);
  };

  const onPause = (event) => {
    logVideoEvent("Video Playback Paused", event);
  };

  const onEnd = (event) => {
    logVideoEvent("Video Playback Ended", event);
    exitFullscreen();
  };

  return (
    <VideoWrapper zindex={activeSlide ? 9999 : 0}>
      <VideoElement
        autoPlay={activeSlide ? "autoplay" : false}
        controls={activeSlide}
        preload="none"
        poster={image}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnd}
        videoStyle={styles}
        sources={videoContent.sources}
        captions={videoContent.captions}
        ref={videoRef}
      />
      <VideoAbout style={activeSlide ? { opacity: 1 } : { transition: "none" }}>
        <Name>{name}</Name> <Title>{title}</Title>
      </VideoAbout>
    </VideoWrapper>
  );
};

const exitFullscreen = () => {
  const { fullScreenElement, webkitFullscreenElement, mozFullScreenElement } =
    document;

  const isFullScreenElement =
    fullScreenElement || webkitFullscreenElement || mozFullScreenElement;

  if (!isFullScreenElement) return;

  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};

export default CarouselVideo;
