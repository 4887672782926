import { Component } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { MARKS } from "@contentful/rich-text-types";

// Components
import Row from "../components/Row";
import PageSEO from "../components/seo/Page";
import MagicLink from "../components/MagicLink";
import MagicSelect from "../components/MagicSelect";
import Text from "../components/Text";
import { MagicSVGImage } from "../components/MagicSVGImage";

// Utils
import { navigate } from "../services/navigation";
import { Font, Color, responsive, rem, Opacity } from "../utils/style";
import { orderArray } from "../utils/order";
import { PoliciesBadge } from "../utils/svg";

const Container = styled.div.attrs({
  className: "container",
})`
  margin-top: 84px;
  margin-bottom: 80px;

  ${responsive.sm`
    margin-top: 92px;
    padding: 0;
  `}

  ${responsive.md`
    margin-top: 128px;
    margin-bottom: 120px;
  `}
`;

const NavigationColumn = styled.div.attrs({
  className: "col-12 col-sm-3",
})`
  margin-bottom: 32px;

  ${responsive.sm`
    margin-bottom: 0;
  `}
`;

const ContentColumn = styled.div.attrs({
  className: "col-12 col-sm-9 col-md-7 offset-md-1",
})``;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${responsive.md`
    margin-bottom: 42px;
  `}
`;

const PageTitle = styled.h1`
  margin: 0;
  font-size: ${rem(34)};
  line-height: ${rem(40)};
  letter-spacing: -0.5px;

  ${responsive.md`
    font-size: ${rem(66)};
    line-height: ${rem(72)};
    letter-spacing: -2px;
  `}
`;

const PoliciesIcon = styled.div`
  img {
    width: 56px;
    height: 56px;

    ${responsive.md`
      height: 96px;
      width: 96px;
    `}
  }
`;

const HeaderRow = styled.hr`
  border: none;
  height: 2px;
  background-color: ${Color.ritualBlue};
  width: 100%;

  margin: 0 0 24px;

  ${responsive.sm`
    margin: 0 0 50px;
  `}

  ${responsive.md`
    margin: 0 0 80px;
  `}
`;

const NavigationLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavigationLink = styled(MagicLink)`
  width: fit-content;

  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  opacity: ${(p) => (p.current ? "1" : Opacity.light)};
  border-bottom: 2px solid
    ${(p) => (p.current ? Color.ritualBlue : "transparent")};

  &:hover {
    opacity: 1;
  }

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(26)};
    margin-bottom: 22px;
  `}

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ContentTitle = styled.h1`
  margin: 0;
  font-weight: 500;

  margin-bottom: 32px;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;

  ${responsive.md`
    margin-bottom: 40px;
    font-size: ${rem(40)};
    line-height: ${rem(54)};
    letter-spacing: -1.17px;
  `}
`;

const ContentSection = styled.section`
  box-sizing: border-box;
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  ${Font.dutch} strong, b {
    ${Font.circular};
  }

  & > p:first-child {
    display: inline-block;
  }

  & > p:nth-child(2) {
    display: inline-block;
  }

  & > p {
    margin-bottom: 24px;
  }

  & > hr:first-of-type {
    background-color: ${Color.ritualBlue};
    height: 2px;
    padding: 0;
    margin: 24px 0;

    ${responsive.md`
      margin: 40px 0;
    `}
  }

  & > h3,
  & > h4 {
    font-size: ${rem(18)};
    line-height: ${rem(28)};

    margin-top: 32px;
    margin-bottom: 24px;
  }

  a:link {
    font-weight: 500;
    cursor: pointer;
    font-size: inherit;
    line-height: ${rem(16)};
    transition: opacity 200ms ease-in-out;
    border-bottom: 1px solid ${Color.ritualBlue};

    &:hover {
      opacity: ${Opacity.light};
    }
  }

  ${responsive.sm`
    & > p {
      display: block;
    }
  `};

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};

    & > h3,
    & > h4 {
      letter-spacing: -0.2px;
      font-size: ${rem(22)};
      line-height: ${rem(32)};

      margin-top: 40px;
    }
  `}
`;

const OrderedNavigationSlugs = [
  "terms-of-service",
  "privacy-policy",
  "cookie-notice",
  "reviews-policy",
  "return-policy",
  "diversity-equity-and-inclusion",
  "accessbility",
];

const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
};

/* eslint-disable react/prefer-stateless-function, react/prop-types */
export default class ContentfulPoliciesPage extends Component {
  constructor(props) {
    super(props);

    let { shortTitle, pageTitle, pageDescription, slug } =
      props.data.contentfulPageBasic;

    this.slug = slug;
    this.shortTitle = shortTitle;
    this.navigationNodes = this.getNavigationNodes();

    this.state = {
      seo: {
        pagePath: slug,
        title: pageTitle,
        description: pageDescription,
      },
    };
  }

  getNavigationNodes() {
    const allPolicyNodes = [...this.props.data.allContentfulPageBasic.nodes];
    return orderArray(allPolicyNodes, OrderedNavigationSlugs, "slug");
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Policy Page",
    });
  }

  handleSelection(slug) {
    navigate(`/${slug}`);
  }

  renderNavigation() {
    const excludedSlugs = ["the-skeptics-affiliate-program-agreement"];

    const filteredNavigationNodes = this.navigationNodes.filter(
      (node) => !excludedSlugs.includes(node.slug),
    );

    const selectOptions = filteredNavigationNodes.map((node) => {
      return {
        value: node.slug,
        title: node.shortTitle,
      };
    });

    return (
      <>
        <NavigationColumn>
          <NavigationLinks className="d-none d-sm-flex">
            {filteredNavigationNodes.map((node) => {
              return (
                <NavigationLink
                  key={node.slug}
                  current={node.slug === this.slug}
                  to={`/${node.slug}`}
                >
                  {node.shortTitle}
                </NavigationLink>
              );
            })}
          </NavigationLinks>
          <div className="d-block d-sm-none">
            <MagicSelect
              options={selectOptions}
              currentOption={{
                title: this.shortTitle,
                value: this.slug,
              }}
              onSelectionMade={this.handleSelection.bind(this)}
            />
          </div>
        </NavigationColumn>
      </>
    );
  }

  render() {
    let { title, body, longBody } = this.props.data.contentfulPageBasic;

    return (
      <>
        <PageSEO {...this.state.seo} />
        <Container>
          <TitleRow>
            <PageTitle>
              <Text
                id="template.article.policies"
                defaultMessage="Our Policies"
              />
            </PageTitle>
            <PoliciesIcon>
              {/** TODO: Update icon */}
              <MagicSVGImage
                width={96}
                height={96}
                alt={"Policies"}
                src={PoliciesBadge}
              />
            </PoliciesIcon>
          </TitleRow>
          <HeaderRow />
          <Row>
            {this.renderNavigation()}
            <ContentColumn>
              <ContentTitle>{title}</ContentTitle>
              {longBody ? (
                <ContentSection className="content">
                  {renderRichText(longBody, richTextOptions)}
                </ContentSection>
              ) : (
                <ContentSection
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: body.childMarkdownRemark.html,
                  }}
                />
              )}
            </ContentColumn>
          </Row>
        </Container>
      </>
    );
  }
}

export const pageQuery = graphql`
  query PoliciesPageQuery($locale: String!, $slug: String!) {
    contentfulPageBasic(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      shortTitle
      body {
        childMarkdownRemark {
          html
        }
      }
      longBody {
        raw
      }
      pageTitle
      pageDescription
      slug
    }
    allContentfulPageBasic(
      filter: { node_locale: { eq: $locale }, type: { eq: "policy" } }
    ) {
      nodes {
        shortTitle
        slug
      }
    }
  }
`;
