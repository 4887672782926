import { useRef, useEffect } from "react";
import styled from "styled-components";

// Utils
import { media } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import { lockScroll } from "../../utils/lockScroll";
import metrics from "../../utils/metrics";

// Components
import FocusTrap from "focus-trap-react";
import CarouselVideo from "./CarouselVideo";

// Styled Elements
const VideoOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 2147483647;
  background: rgba(255, 255, 255, 0.9);
  transition: opacity 500ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
`;

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  width: 85vw;
  height: 62vw;
  max-width: 970px;
  max-height: 547px;

  ${media.notDesktop`
    max-width: 647px;
    max-height: 365px;
  `};

  ${media.mobile`
    width: 100vw;
    margin: 0 auto;
  `};
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: -50px;
  display: flex;
  justify-content: flex-end;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  ${media.mobile`
    right: 20px;
  `};
`;

export default function TestimonialsCarouselVideos({
  slides,
  activeSlideIndex,
  handleGoToNextSlide,
  handleGoToPreviousSlide,
  setShowVideos,
}) {
  const videoOverlayRef = useRef(null);
  const offsets = generateTransformOffsets(slides, activeSlideIndex);
  const activeSlide = slides[activeSlideIndex];

  function closeCarousel() {
    lockScroll(false, videoOverlayRef.current);
    setShowVideos(false);
  }

  useEffect(() => {
    function logCarouselPosition(message) {
      metrics.track("Video Testimonials Engagement", {
        action: message,
        position: activeSlideIndex + 1,
        subject: slides[activeSlideIndex].name,
      });
    }

    logCarouselPosition("Playback Carousel Expanded");
    lockScroll(true, videoOverlayRef.current);

    const handleKeyDown = function (event) {
      switch (event.keyCode) {
        case 27: // Update to enum with PR #3134
          closeCarousel();
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      logCarouselPosition("Playback Carousel Closed");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transform = offsets[activeSlideIndex];
  const opacity = transform > 200 || transform < -200 ? 0 : 1;
  const scale = activeSlide ? 1 : 0.75;

  const styles = {
    transform: `translateX(${transform}%) scale(${scale})`,
    opacity,
  };

  const slide = slides[activeSlideIndex];

  return (
    <FocusTrap>
      <VideoOverlay ref={videoOverlayRef}>
        <VideoContainer>
          <CloseButton onClick={closeCarousel}>
            <Icons.Close />
          </CloseButton>

          <CarouselVideo
            activeSlide={activeSlide}
            handleGoToNextSlide={handleGoToNextSlide}
            handleGoToPreviousSlide={handleGoToPreviousSlide}
            index={activeSlideIndex}
            key={slide.shortname}
            slide={slide}
            styles={styles}
          />
        </VideoContainer>
      </VideoOverlay>
    </FocusTrap>
  );
}

function generateTransformOffsets(slides, activeSlideIndex) {
  const offsets = slides.map((_, index) => {
    if (slides.length / 2 < index) {
      return (index - slides.length) * 100 * -1;
    } else {
      return index * 100 * -1;
    }
  });

  let newOffsets = [];

  for (let i = 0; i < offsets.length; i++) {
    let pointer =
      (Math.abs(i - offsets.length) + activeSlideIndex) % offsets.length;
    newOffsets.push(offsets[pointer]);
  }

  return newOffsets;
}
